.card-hover:hover{
    transform: scale(1.01);
    box-shadow: 0 10px 20px rgba(0,0,0,.12), 0 4px 8px rgba(0,0,0,.06);
}

.my-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0.42rem;
    background-color: rgba(255, 255, 255, 0.452);
}

.text-secondary {
    color: #007bff !important;
  }

  .text-info {
    color: #3699ff !important;
  }

  .btn-link {
    color: #3699ff !important;
  }

  .btn-info {
    color: #ffffff;
    background-color: #037fff;
    border-color: #037fff;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .btn-outline-info {
    color: #037fff !important;
    background-color: #ffffff00 !important;
    border-color: #037fff !important;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .btn-primary:hover {
    color: #ffffff !important;
    background-color: #007bff !important;
    border-color: #007bff !important;
  }

  .btn.btn-light-info {
    color: #3699ff !important;
    background-color: #E1F0FF !important;
    border-color: transparent;
  }

  .btn.btn-light-info:hover {
    color: #ffffff !important;
    background-color: #037fff !important;
    border-color: transparent;
  }

  /* .text-muted {
      color: #9BB2C5 !important;
  } */

  /* h1 {
    font-family: 'Gilda Display', serif;
  } */


  body {
    background: #ffffff;
  }

  .btn-outline-orange {
    color: #fd7c67 !important;
    background-color: #fd7b6700;
    border-color: #fd7c67;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .btn-orange {
    color: #ffffff !important;
    background-color: #fd7c67;
    border-color: #fd7c67;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .btn-light-orange {
    color: #fd7c67 !important;
    background-color: #ffe6e2;
    border-color: #ffe6e2;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .btn-light-orange:hover {
    color: #ffffff !important;
    background-color: #fd7c67 !important;
    border-color: #fd7c67 !important;
  }

  .text-orange {
    color: #fd7c67 !important;
  }

  .btn-light-brown {
    color: #dac0b3 !important;
    background-color: rgb(255, 242, 240);
    border-color: rgb(255, 242, 240);
    -webkit-box-shadow: none;
    box-shadow: none;
  }


  .text-brown {
    color: #dac0b3 !important;
  }

  .bg-light-brown {
    background-color: rgb(255, 242, 240);
  }

  .label.label-light-success {
    color: #28a745 !important;
    background-color: #CCF2EC !important;
  }

  .label.label-success {
    color: #ffffff !important;
    background-color: #28a745 !important;
    font-size: 12px;
  }

  .label.label-danger {
    font-size: 12px;
  }

  .text-info{
    color: #17a2b8 !important;

  }

  .text-success {
    color: #28a745 !important;


  }

  .bg-success {
    background-color: #28a745 !important;

  }

  .bg-info {
    background-color: #17a2b8 !important;
  }

  .bg-morado {
    background-color: #4c38e3;
  }

  .bg-cafe {
    background-color: #9f7b52;
  }

  .label-light-info {

    background-color: #17a3b836 !important;
    color: #17a2b8 !important;

  }

  /* nav tab line weight */
  .nav.nav-tabs.nav-tabs-line .nav-link:hover:not(.disabled), .nav.nav-tabs.nav-tabs-line .nav-link.active, .nav.nav-tabs.nav-tabs-line .show > .nav-link {
    background-color: transparent;
    border: 0;
    border-bottom: 5px solid #007bff !important;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
}

.btn-info {
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.btn-outline-success {
  color: #28a745 !important;
  background-color: #fd7b6700;
  border-color: #28a745 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.table-hover tbody tr:hover {
  color: #3F4254;
  background-color: rgba(0,0,0,.040) !important;
}

.navi.navi-active .navi-item .navi-link.active {
  background-color: #007bff;
  color: white;
}

.navi .navi-item .navi-link {
  transition: all 0.15s ease;
  color: #3F4254;
}

.navi .navi-item .navi-link {
  display: flex;
  align-items: center;
  padding: 0.1rem 0rem;
}
 
.bg-secondary {
  background-color: rgb(239, 242, 255) !important;
}

.spinner:before {
  width: 150px;
  height: 150px;
  margin-top: -0.75rem;
}


.checkbox > span {
  background-color: #ffffff;
  border: 1px solid #5c8bbd;
  border-radius: 0.25rem;
}

.alert.alert-success {
  background-color: #28a7463d;
  border-color:  transparent;
  color: #28a745;
  font-size: 16px !important;
  font-weight: 600;
}

.alert.alert-danger {
  background-color: #dc35463c;
  border-color:  transparent;
  color: #dc3545;
  font-size: 16px !important;
  font-weight: 600;
}

/* SELECT CSS */
.css-tlfecz-indicatorContainer {
  color: #007bff !important;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 8px;
  -webkit-transition: color 150ms;
  transition: color 150ms;
  box-sizing: border-box;
}


.css-1okebmr-indicatorSeparator {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  background-color: transparent !important;
  margin-bottom: 8px;
  margin-top: 8px;
  width: 1px;
  box-sizing: border-box;
}

.css-yk16xz-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: #E4E6EF !important;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0!important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
}

.css-1wa3eu0-placeholder {
  color: #b6bac8 !important;
  margin-left: 2px;
  margin-right: 2px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  box-sizing: border-box;
  font-size: 16px !important;
}

/* FIN SELECT CSS */

.btn-hover-success:hover {
  background-color: #007bff !important;
}

/* PREPPEND APPEND */
.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.65rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #7ba2cb9f;
  text-align: center;
  white-space: nowrap;
  background-color: #648ab21f !important;
  border: 1px solid #E4E6EF;
  border-radius: 0.42rem;
}