#sidebar.active {
    padding-top: 20px;
    min-width: 80px;
    max-width: 80px;
    text-align: center;
}

#sidebar.active .sidebar-header h3,
#sidebar.active .CTAs {
    display: none;
}

#sidebar.active .sidebar-header strong {
    display: block;
}


#sidebar.active ul li a {
    padding: 8px 5px;
    text-align: center;
    font-size: 0.6em;
}

#sidebar.active ul li a i {
    margin-right: 0;
    display: block;
    font-size: 1.8em;
    margin-bottom: 5px;
}

#sidebar.active ul ul a {
    padding: 10px !important;
}

#sidebar.active .dropdown-toggle::after {
    top: auto;
    bottom: 10px;
    right: 50%;
    -webkit-transform: translateX(50%);
    -ms-transform: translateX(50%);
    transform: translateX(50%);
}

#sidebar ul li.active > a,
a[aria-expanded="true"] {
    color: #293241;
}


#sidebar.active .sidebar-logo {
    display: none;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
@media (max-width: 768px) {

    #sidebar.active {
        min-width: 160px;
        margin-left: 0 !important;
    }

    #sidebar.active ul li a span {
        font-size: 1em;
    }

    #sidebar.active ul li a i {
        font-size: 3em;
    }
}
